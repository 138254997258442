import SCard from '../../components/ui/s-card'

export default {
  name: 'NavigationCard',

  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },

  components: {
    SCard,
  },
}
