var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-space"},[_c('s-container',{attrs:{"large":""}},[_c('router-link',{staticClass:"my-space__back",attrs:{"to":{
        name: 'company',
        params: {
          agree: true,
          slug: _vm.company.slug,
          slugId: _vm.company.slugId,
          source: 'COLLABORATOR'
        }
      }}},[_c('img',{attrs:{"src":_vm.backIcon,"alt":"Back"}})]),_c('s-title',[_vm._v(_vm._s(_vm.$t('MY_SPACE')))]),_c('nav',{staticClass:"navigation-card--wrapper"},[_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_c('swiper-slide',[_c('NavigationCard',{attrs:{"cardData":_vm.myReports},on:{"navigate":function($event){return _vm.openTab('my-reports')}}})],1),(_vm.isConnectEnabled)?_c('swiper-slide',[_c('NavigationCard',{attrs:{"cardData":_vm.myMatchReports},on:{"navigate":function($event){return _vm.openTab('my-match-reports')}}})],1):_vm._e(),_c('swiper-slide',[_c('NavigationCard',{attrs:{"cardData":_vm.myArchive},on:{"navigate":function($event){return _vm.openTab('my-archived')}}})],1),(_vm.isIssueFeatureEnabled)?_c('swiper-slide',[_c('NavigationCard',{attrs:{"cardData":_vm.myIssues},on:{"navigate":function($event){return _vm.openTab('my-issues')}}})],1):_vm._e()],1)],1),_c('transition',[_c('main',[_c('h4',{staticClass:"page-title"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.pageTitle)))])]),_c('router-view')],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }