import { mapGetters, mapState } from 'vuex'
import router from '../../plugins/router'

import SContainer from '../../components/ui/s-container'
import STitle from '../../components/ui/s-title'
import NavigationCard from '../../components/NavigationCard'

import backIcon from '../../assets/images/icone_voltar_simples.svg'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
  name: 'MySpace',

  components: {
    NavigationCard,
    STitle,
    SContainer,
    Swiper,
    SwiperSlide,
  },

  mounted() {
    const { name } = router.currentRoute

    this.setPageTitle(name)
    this.activeCard(name)
  },

  data() {
    return {
      backIcon,
      myReports: {
        badge: false,
        style: 'card-blue card-sent',
        text: 'SEE_REPORTS',
        category: 'SENT',
        active: true,
      },
      myMatchReports: {
        badge: false,
        style: 'card-green card-match',
        text: 'SEE_REPORTS',
        category: 'MATCHES',
        active: false,
      },
      myArchive: {
        badge: false,
        style: 'card-dark card-draft',
        text: 'SEE_REPORTS',
        category: 'DRAFTS',
        active: false,
      },
      myIssues: {
        badge: false,
        style: 'card-dark card-issues',
        text: 'SEE_MY',
        category: 'ISSUES',
        active: false,
      },
      pageTitle: 'SENT_REPORTS',
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        pagination: false,
      },
    }
  },

  computed: {
    ...mapState(['unreadIssue', 'unreadReports', 'company']),
    ...mapGetters(['isConnectEnabled', 'isIssueFeatureEnabled']),
  },

  methods: {
    openTab(name) {
      this.setPageTitle(name)
      this.activeCard(name)
      if (router.currentRoute.name !== name) {
        router.push({ name })
      }
    },

    activeCard(route) {
      this.myReports.active =
        route.match(/my-reports/) || route.match(/my-space/)
      this.myArchive.active = route.match(/my-archived/)
      this.myIssues.active = route.match(/my-issues/)
      this.myMatchReports.active = route.match(/my-match-reports/)
    },

    setPageTitle(route) {
      if (route.match(/reports/)) this.pageTitle = 'SENT_REPORTS'
      if (route.match(/archived/)) this.pageTitle = 'ARCHIVED'
      if (route.match(/issues/)) this.pageTitle = 'ISSUES'
      if (route.match(/match-reports/)) this.pageTitle = 'MATCH_REPORTS'
    },
  },

  watch: {
    reports() {
      this.myReports.badge = this.unreadReports
    },

    unreadReports() {
      this.myReports.badge = this.unreadReports
    },

    unreadIssue() {
      this.myIssues.badge = this.unreadIssue
    },
  },
}
